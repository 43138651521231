import { useState } from 'react'

import _ from 'lodash'

import type { DownloadableFile } from '@/types'
import { generateLinkToDownload } from '@/utils/frontend/generateLinkToDownload'

export type FilesByCategories<T extends DownloadableFile = DownloadableFile> = Record<string, T[]>;

type UseSelectedFiles = <T extends DownloadableFile>(
  filesByCategories: FilesByCategories<T>,
) => {
  selectedIds: string[]
  isAllSelected: boolean
  onSelection: (newState: string) => void
  linkToDownload?: string
  allDocuments: DownloadableFile[]
  onSelectAll: () => void
}

export const useSelectedFiles: UseSelectedFiles = (filesByCategories) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const linkToDownload = generateLinkToDownload(selectedIds)

  const onSelection = (newState: string) => {
    if (selectedIds.includes(newState)) {
      setSelectedIds((prevState) => prevState.filter((el) => el !== newState))
      return
    }
    setSelectedIds((prevState) => [...prevState, newState])
  }

  const allDocuments: DownloadableFile[] = _.flatten(Object.values(filesByCategories))

  const isAllSelected = allDocuments.length === selectedIds.length

  const onSelectAll = () => {
    if (isAllSelected) {
      Object.keys(filesByCategories).map((category) =>
        filesByCategories[category].map(() => { setSelectedIds(() => []); }),
      )
      return
    }
    const newSelectedCertificates = allDocuments
      .filter((document) => !selectedIds.includes(document.id))
      .map((document) => document.id)

    if (newSelectedCertificates.length > 0) {
      setSelectedIds((prevState) => [...prevState, ...newSelectedCertificates])
    }
  }
  return {
    selectedIds,
    isAllSelected,
    allDocuments,
    onSelectAll,
    linkToDownload,
    onSelection,
  }
}
